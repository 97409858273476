import * as React from 'react'
import { Helmet } from 'react-helmet'

import './layout.scss'
import 'typeface-quicksand'

import { Header } from './Header'
import { Footer } from './Footer'

export const Layout: React.FC = ({ children }) => {
  return (
    <>
      <Helmet
        title="Kåda Development"
        meta={[
          {
            name: 'description',
            content: 'Makers of beautiful high-performance mobile and web apps',
          },
          {
            name: 'keywords',
            content:
              'app developer, maker, konsult, webbutvecklare, utvecklare, systemutvecklare, webbsida, app, norrköping, linköping, östergötland, developer, flutter, apps',
          },
        ]}
      >
        <html lang="en" />
      </Helmet>
      <Header />
      <main>{children}</main>
      <Footer />
    </>
  )
}
