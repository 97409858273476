import * as React from 'react'

import * as logo from '@kadadev/assets/letterhead_background.svg'
import * as styles from './Header.module.scss'

export const Header = () => (
  <header className={styles.header}>
    <img className={styles.logo} src={logo} alt="Kåda Development" />
  </header>
)
