import * as React from 'react'

export const Footer = () => (
  <footer>
    <h2>Get in touch</h2>

    <a href="mailto:info@kada.se">info@kada.se</a>
    <a href="tel:+46730262686">+46 (0) 730 26 26 86</a>

    <address>
      <h3>Address</h3>
      Kåda Development AB
      <br /> c/o Gustav Bylund
      <br /> Kaptensgatan 19A
      <br /> 582 12 Linköping
      <br />
    </address>
  </footer>
)
